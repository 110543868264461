import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import SimpleInput from "../../../Components/Inputs/simpleInput";
import DatePicker from "react-datepicker";
import { Col, Row, Label, InputGroup } from "reactstrap";
import { Button } from "react-bootstrap";

const BookingHistory = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const { bookings: bookingsData, error } = useSelector(
    (state) => state.bookings
  );

  const options = [
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Unpaid" },
  ];
  
  useEffect(() => {
    if (bookingsData) {
      let data = bookingsData;
      if (emailFilter) {
        data = data.filter((row) => row?.userId?.email?.includes(emailFilter));
      }
      if (statusFilter) {
        data = data.filter((row) => row?.payment === statusFilter);
      }
      if (dateFilter) {
        data = data.filter(
          (row) =>
            new Date(row.dateArrive).toLocaleDateString() ===
            new Date(dateFilter).toLocaleDateString()
        );
      }
      const currentDate = new Date();
      data = data.filter(
        (row) => new Date(row.dateDepart) < currentDate
      );
      setFilteredData(data);
    }
  }, [bookingsData, emailFilter, statusFilter, dateFilter]);

  const clearFilters = () => {
    setEmailFilter("");
    setStatusFilter("");
    setDateFilter("");
  };

  return (
    <div className="page-body">
      <Breadcrumbs title="Booking History" />
      <div>
        <Row>
          <Col
            lg="2"
            sm="3"
            className="d-flex"
            style={{
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "flex-start",
            }}
          >
            <SimpleInput
              value={emailFilter}
              className="form-control w-100"
              onChange={(e) => setEmailFilter(e.target.value)}
              placeholder="Filter by Email"
            />
          </Col>
          <Col
            lg="2"
            sm="3"
            className="d-flex"
            style={{
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "flex-start",
            }}
          >
            <Select
              className="w-100"
              options={options}
              isClearable={true}
              value={
                options.find((option) => option.value === statusFilter) || null
              }
              onChange={(e) => {
                setStatusFilter(e?.value || "");
              }}
            />
          </Col>
          <Col
            lg="2"
            sm="3"
            className="d-flex"
            style={{
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "flex-start",
            }}
          >
            <DatePicker
              name="selectedDate"
              className="form-control digits w-100"
              showPopperArrow={false}
              selected={dateFilter}
              dateFormat="yyyy-MM-dd"
              onChange={(date) => {
                setDateFilter(date);
              }}
            />
          </Col>
          <Col
            lg="2"
            sm="3"
            className="d-flex"
            style={{
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="primary"
              className=" pull-right "
              style={{ marginLeft: "10px" }}
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>

        <DataTable
          columns={[
            {
              name: "Email",
              selector: (row) => row?.userId?.email || "N/A",
              sortable: true,
              center: true,
            },
            {
              name: "Payment Status",
              selector: (row) => row?.payment || "N/A",
              sortable: true,
              center: true,
            },
            {
              name: "Arrival Date",
              selector: (row) =>
                row?.dateArrive
                  ? new Date(row.dateArrive).toLocaleDateString()
                  : "N/A",
              sortable: true,
              center: true,
            },
          ]}
          data={filteredData}
          striped={true}
          pagination
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
          highlightOnHover={true}
        />
      </div>
    </div>
  );
};

export default BookingHistory;
