import axiosInstance from "../../api/axios";

const CREATE_Booking = `${process.env.REACT_APP_BACK_URL}/api/v1/booking/addBooking`;
const REVIEW_Booking = `${process.env.REACT_APP_BACK_URL}/api/v1/booking/calculateBookingPrices`;

const GET_ALL_ESTABLISHMENT_MEALS = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/establishmentMeals/getEstablishementMealsbyEstablishementId/${establishmentId}`;
const GET_ALL_BOOKING_BY_ESTABLISHMENT_ID = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/booking/getBookingByEstablishement/${establishmentId}`;

const UPDATE_ALL_BOOKING_BY_ID = (id) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/booking/updateBooking/${id}`;

export const createBookings = async (bookingsData) => {
  try {
    const response = await axiosInstance.post(CREATE_Booking, bookingsData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reviewBookings = async (bookingsData) => {
  try {
    const response = await axiosInstance.post(REVIEW_Booking, bookingsData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllBookingByEstablishmentId = async (establishmentId) => {
  try {
    const response = await axiosInstance.get(
      GET_ALL_BOOKING_BY_ESTABLISHMENT_ID(establishmentId)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBookingById = async (id, bookingData) => {
  try {
    const response = await axiosInstance.post(
      UPDATE_ALL_BOOKING_BY_ID(id),
      bookingData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const getAllEstablishmentMeals = async (
//   establishmentId
// ) => {
//   try {
//     const response = await axiosInstance.get(
//       GET_ALL_ESTABLISHMENT_MEALS(establishmentId)
//     );

//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };
