import React, { useEffect, useState } from "react";
import { Col, Row, Label, Input, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../Components/Inputs/simpleInput";
import Select from "react-select";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import {
  REGIONS,
  TYPE_ETABLISHEMENT,
  TYPE_PRESTATION,
} from "../../Constant/index";
import { updateEtablishement } from "../../redux-toolkit/slices/etablishementSlice";
import { getAffectationByIdAsync } from "../../redux-toolkit/slices/authSlice";
import { toast } from "react-toastify";
import Swipper from "../Swiper/Swiper";
import { dynamicImage } from "../../Service";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { usePermission } from "../../Service/usePermission";

const LocationMarker = ({ position, setPosition }) => {
  useMapEvents({
    click(e) {
      setPosition([e.latlng.lat, e.latlng.lng]);
    },
  });

  // Define custom icon
  const customIcon = L.icon({
    iconUrl:
      "https://icon-library.com/images/position-icon/position-icon-8.jpg",
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
  });
  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

// Set Leaflet's default marker icon URLs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const EtablishementSetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const [position, setPosition] = useState([
    34.30714385628804, 9.492187500000002,
  ]); // Default position
  const [zoom, setZoom] = useState(5);
  const [show, setShow] = useState(false);
  const [oldImages, setOldImages] = useState(
    affectationSelectedData?.establishments?.images
  );

  //update the swiper whenver the affectationSelectedData?.establishments?.images  changes
  useEffect(() => {
    setOldImages(affectationSelectedData?.establishments?.images);
  }, [affectationSelectedData?.establishments?.images]);

  const schema = yup.object().shape({
    name: yup.string().required("You must enter a name"),
    description: yup
      .string()
      .required("You must enter a description about your etablishement"),
    type: yup.string().required("You must select a type"),
    region: yup.string().required("You must select a region"),
    phone: yup
      .string()
      .required("You must enter the phone number")
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(8, "Phone number must be at least 10 digits")
      .max(13, "Phone number must be at most 15 digits"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      images: null,
      oldImages: oldImages,
      name: affectationSelectedData?.establishments?.name || "",
      description: affectationSelectedData?.establishments?.description || "",
      type: affectationSelectedData?.establishments?.type || "",
      currency: affectationSelectedData?.establishments?.currency || "",
      region: affectationSelectedData?.establishments?.region || "",
      phone: affectationSelectedData?.establishments?.phone || "",
    },
  });

  console.log(
    "affectationSelectedData?.establishments?.currency",
    affectationSelectedData?.establishments?.currency
  );
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setValue("images", files);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("description", data?.description);
    formData.append("phone", data.phone);
    formData.append("type", data?.type);
    formData.append("currency", data?.currency);
    formData.append("region", data?.region);
    formData.append("position", JSON.stringify(position));
    formData.append("oldImages", oldImages);
    for (let i = 0; i < data?.images?.length; i++) {
      formData.append("images", data.images[i]);
    }

    const result = await dispatch(
      updateEtablishement({
        data: formData,
        id: affectationSelectedData?.establishments?._id,
      })
    );
    if (updateEtablishement.rejected.match(result)) {
      toast.error("Error update Etablishement !");
    } else {
      toast.success("Etablishement updated Successfully...!");
      const affectationId = localStorage.getItem("affectation");
      dispatch(getAffectationByIdAsync(affectationId));
    }
  };

  const handleMapClick = (e) => {
    setPosition([e.latlng.lat, e.latlng.lng]);
  };

  useEffect(() => {
    if (affectationSelectedData) {
      if (affectationSelectedData?.establishments?.position) {
        const positionString = affectationSelectedData?.establishments?.position
          .replace("[", "")
          .replace("]", "")
          .trim();
        const positionArray = positionString
          .split(",")
          .map((coord) => parseFloat(coord.trim()));
        setPosition(positionArray);
        setZoom(15);
        setShow(true);
      } else {
        setShow(true);
      }
    }
  }, [affectationSelectedData]);

  const CurrencyData = [
    { label: "EUR", value: "EUR" },
    { label: "USD", value: "USD" },
    { label: "TND", value: "TND" },
  ];

  return (
    <Col xl="12">
      <form>
        <Row>
          <Col xl="3">
            <Controller
              name="oldImages"
              control={control}
              render={({ field }) => (
                <>
                  {oldImages && oldImages.length > 0 ? (
                    <Swipper
                      oldImages={oldImages}
                      setOldImages={setOldImages}
                      dispatch={dispatch}
                    />
                  ) : (
                    <div className="d-flex align-item-center justify-content-center w-100 h-100">
                      <Image
                        src={dynamicImage("ecommerce/options.png")}
                        className=" m-auto col-sm-4  d-block img-fluid  "
                      />
                    </div>
                  )}
                </>
              )}
            />
          </Col>
          <Col xl="9">
            <Row>
              <Col md="6">
                <Label>{t("Establishment name")}</Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input"
                      className="form-control"
                      placeholder={t("Name")}
                      errors={errors.name?.message}
                      disabled={usePermission("static", "settings", "!update")}
                    />
                  )}
                />
              </Col>
              <Col md="6">
                <Label>{t("Description")}</Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input"
                      className="form-control"
                      placeholder={t("Description")}
                      errors={errors.description?.message}
                      disabled={usePermission("static", "settings", "!update")}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Col md="6">
                <Label>{t("Type")}</Label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={
                        affectationSelectedData?.establishments?.onModel ===
                        "Establishment"
                          ? TYPE_ETABLISHEMENT
                          : TYPE_PRESTATION
                      }
                      {...field}
                      value={
                        affectationSelectedData?.establishments?.onModel ===
                        "Establishment"
                          ? TYPE_ETABLISHEMENT.find(
                              (option) => option.value === field.value
                            )
                          : TYPE_PRESTATION.find(
                              (option) => option.value === field.value
                            )
                      }
                      onChange={(value) => field.onChange(value.value)}
                      isDisabled={usePermission(
                        "static",
                        "settings",
                        "!update"
                      )}
                    />
                  )}
                />
                {errors.type && (
                  <div className="error-msg-input">{errors.type.message}</div>
                )}
              </Col>
              <Col md="6">
                <Label>{t("Region")}</Label>
                <Controller
                  name="region"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={REGIONS}
                      {...field}
                      value={REGIONS.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(value) => field.onChange(value.value)}
                      isDisabled={usePermission(
                        "static",
                        "settings",
                        "!update"
                      )}
                    />
                  )}
                />
                {errors.region && (
                  <div className="error-msg-input">{errors.region.message}</div>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Col md="6">
                <Label>{t("Phone")}</Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input"
                      className="form-control"
                      placeholder={t("Phone")}
                      errors={errors.phone?.message}
                      disabled={usePermission("static", "settings", "!update")}
                    />
                  )}
                />
              </Col>

              <Col md="6">
                <Label className="col-form-label pt-0">{t("Images")}</Label>
                <Controller
                  name="images"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="file"
                      multiple
                      onChange={(e) => {
                        field.onChange(e);
                        handleFileChange(e);
                      }}
                      disabled={usePermission("static", "settings", "!update")}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Col md="6">
                <Label>{t("Currency")}</Label>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={CurrencyData?.map((t) => ({
                        label: t.label,
                        value: t.value,
                      }))}
                      {...field}
                      value={CurrencyData.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(value) => field.onChange(value.value)}
                      isDisabled={usePermission(
                        "static",
                        "settings",
                        "!update"
                      )}
                    />
                  )}
                />
                {errors.currency && (
                  <div className="error-msg-input">
                    {errors.currency.message}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {show && (
          <Row style={{ paddingTop: "10px" }}>
            <Label>{t("Select your localisation")}</Label>
            <MapContainer
              center={position}
              zoom={zoom}
              style={{ width: "100%", height: "400px" }}
              onClick={handleMapClick}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <LocationMarker position={position} setPosition={setPosition} />
            </MapContainer>
          </Row>
        )}
        <Row style={{ paddingTop: "10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <Button
              onClick={handleSubmit(onSubmit)}
              color="secondary"
              disabled={usePermission("static", "settings", "!update")}
            >
              {t("Save")}
            </Button>
          </div>
        </Row>
      </form>
    </Col>
  );
};

export default EtablishementSetting;
