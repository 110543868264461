import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { getAllBookings, updateBookingStatus } from '../../../../redux-toolkit/slices/bookingSlice';

const PreviewBookingModal = ({ isOpen, toggle, booking,establishmentId }) => {
  const dispatch = useDispatch();
  if (!booking) return null;

  const handleUpdateStatus = async (status) => {
    try {
      const response = await dispatch(
        updateBookingStatus({
          id: booking._id,
          bookingData: { ...booking,status: status },
        })
      );
      if (updateBookingStatus.rejected.match(response)) {
        toast.error(`Failed to update booking status to ${status}`);
      } else {
        toast.success(`Booking status updated to ${status} successfully`);
        dispatch(getAllBookings(establishmentId));
        toggle();
      }
    } catch (error) {
      console.error("Error updating booking status:", error);
      toast.error("An error occurred while updating the booking status");
    }
  };
  
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Booking Preview</ModalHeader>
      <ModalBody>
        <p><strong>User Email:</strong> {booking.userId.email}</p>
        <p><strong>Room Number:</strong> {booking.roomId.roomNumber}</p>
        <p><strong>Meals:</strong> {booking.mealsId.name}</p>
        <p><strong>Adults:</strong> {booking.nbAdult}</p>
        <p><strong>Children:</strong> {booking.nbEnfant}</p>
        <p><strong>Total Price:</strong> {booking.totalPrice}</p>
        <p><strong>Status:</strong> {booking.status}</p>
      </ModalBody>
      <div style={{ margin: "20px", display: "flex", gap:"10px" }}>
        <Button
          color="success"
          onClick={() => handleUpdateStatus("accepted")}
        >
          Confirm
        </Button>
        <Button
          color="danger"
          onClick={() => handleUpdateStatus("rejected")}
        >
          Decline
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default PreviewBookingModal;
