import { H6, Image, P } from "../../AbstractElements";
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import UpdateAffectation from "../Client/updateAffectation";

const ListViewCard = ({ item, affectationData, setAddAffectation, setIsOpen, isOpen2, setIsOpen2 }) => {
  const [isActiveInEstablishment, SetIsActiveInEstablishment] = useState(false);
  // const [isOpen2, setIsOpen2] = useState(false);
  
  const [loading, setLoading] = useState(true);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );

  const selectedAffectation =  affectationData?.find(element => element.status === 'active')
  useEffect(() => {
    const checkAffectationStatus = async () => {
      SetIsActiveInEstablishment(
        affectationData?.some(
          (affectation) =>
            affectation?.establishment?._id ===
              affectationSelected.establishments._id &&
            affectation.status === "active"
        )
      );
      setLoading(false);
    };
    checkAffectationStatus();
  }, [affectationData, affectationSelected]);

  const handleUpdate = () => {
    setIsOpen2(true);
    
  }

  return (
    <Fragment>
      <Card key={item?._id} className={"ribbon-vertical-left-wrapper"}>
        <div
          className={
            "ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary "
          }
        >
          <i className="icofont icofont-love"></i>
        </div>

        <div className="job-search">
          <CardBody>
            <div className="d-flex">
              <Image
                className="img-40 img-fluid m-r-20"
                src={item.image ? process.env.REACT_APP_MINIO_URL + item.image : ""}
                alt="user"
              />
              <div className="flex-grow-1">
                <H6>
                  <Link to="">
                    {item.firstName} {item.lastName}
                  </Link>
                  {loading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="pull-right"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : isActiveInEstablishment ? (
                    <Button variant="primary " className="pull-right" onClick={handleUpdate}>
                      <FaEdit className="mr-2" /> Update
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className=" pull-right "
                      style={{ marginLeft: "10px" }}
                      onClick={() => setAddAffectation(true)}
                    >
                      <FaPlus className="mr-2" /> Add{" "}
                    </Button>
                  )}
                </H6>
                <P>{item.uniqueId}</P>
              </div>
            </div>
            <div></div>
          </CardBody>
        </div>
      </Card>
      <UpdateAffectation
            isOpen2={isOpen2}
            setIsOpen2={setIsOpen2}
            selectedAffectation={selectedAffectation}
            setIsOpen={setIsOpen}
          />
    </Fragment>
  );
};

export default ListViewCard;
