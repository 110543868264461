import React, { Fragment, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllMeals } from "../../../redux-toolkit/slices/mealsSlice";
import {
  availabilityRoomBookings,
  cleanRoomsForBooking,
  fetchRoomsAsync,
} from "../../../redux-toolkit/slices/roomsSlice"; // Add this line
import "./Calendar.css"; // Import your custom styles
import AvailabileRoom from "../AvailabileRoom";
import BookingSummary from "../BookingSummary";
import { getAllBookings } from "../../../redux-toolkit/slices/bookingSlice";
import { createBookingsAsync } from "../../../redux-toolkit/slices/bookingSlice";
import { toast } from "react-toastify";
import CreateBookingModal from "./modals/CreateBookingModal";
import PreviewBookingModal from "./modals/PreviewBookingModal";

const DragCalendar = () => {
  const dispatch = useDispatch();
  const paymentOptions = ["Paid", "Unpaid", "In-progress"];
  const [step, setStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [selectedBookingPreview, setSelectedBookingPreview] = useState(null);
  const [modalSize, setModalSize] = useState("xl");
  const [reviewDataToSubmit, setReviewDataToSubmit] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const establishmentId = affectationSelectedData?.establishments?._id;

  const roomAvailableForBooking = useSelector(
    (state) => state.rooms.roomAvailableForBooking
  );

  const { bookings: bookingsData, error } = useSelector(
    (state) => state.bookings
  );

  const [formData, setFormData] = useState({
    dateArrive: "",
    dateDepart: "",
    bookings: [
      { roomId: "Room 1", nbAdulte: 1, nbEnfant: 0, roomType: "standard" },
    ],
  });

  useEffect(() => {
    if (establishmentId) {
      dispatch(getAllMeals(establishmentId));
    }
  }, [dispatch, establishmentId]);
  useEffect(() => {
    if (establishmentId) {
      dispatch(getAllBookings(establishmentId));
    }
  }, [dispatch, establishmentId]);

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");

    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let id = eventEl.getAttribute("data-id");
          return {
            title: title,
            id: id,
          };
        },
      });
    }
  }, []);

  const handleDateSelect = async (selectInfo) => {
    const { startStr, endStr } = selectInfo;
    setSelectedDateRange({
      start: startStr,
      end: endStr,
    });
    setFormData({
      ...formData,
      dateArrive: startStr,
      dateDepart: endStr,
    });
    setModalOpen(true);
    selectInfo.view.calendar.unselect(); // Clear date selection
  };

  const handleFormChange = (e, index) => {
    const { name, value } = e.target;
    const newValue =
      name.includes("nbAdulte") || name.includes("nbEnfant")
        ? Number(value)
        : value;

    setFormData((prevState) => {
      const updatedBookings = prevState.bookings.map((booking, i) =>
        i === index ? { ...booking, [name]: newValue } : booking
      );
      return { ...prevState, bookings: updatedBookings };
    });
  };

  const handleAddBooking = () => {
    const newRoomNumber = formData.bookings.length + 1;
    setFormData((prevState) => ({
      ...prevState,
      bookings: [
        ...prevState.bookings,
        { roomId: `Room ${newRoomNumber}`, nbAdulte: 1, nbEnfant: 0 }, // Add new room
      ],
    }));
  };
  const handleDeleteBooking = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      bookings: prevState.bookings.filter((_, i) => i !== index),
    }));
  };
  const handleCloseModal = () => {
    setModalOpen(false); // Close modal
    dispatch(cleanRoomsForBooking()); // Clear available rooms
    setSelectedBooking(null);
    setStep(0);
    setModalSize("xl");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const transformedData = formData.bookings.map((booking) => ({
        dateArrive: formData.dateArrive,
        dateDepart: formData.dateDepart,
        nbAdulte: booking.nbAdulte,
        roomType: booking.roomType,
        nbEnfant: booking.nbEnfant,
      }));

      // Create the final data object to send
      const dataToSend = {
        bookings: transformedData,
        establishmentId: establishmentId,
      };
      const response = await dispatch(availabilityRoomBookings(dataToSend));
    } catch (error) {
      console.error(
        "Error creating booking:",
        error.response?.data || error.message
      );
    }
  };

  const handleReviewBook = (data) => {
    setSelectedBooking(data);
    setModalSize("lg");
    setStep(1);
  };

  const handleBack = () => {
    setStep(0);
    setModalSize("xl");
  };

  const SubmitBooking = async () => {
    const finalData = {
      ...reviewDataToSubmit,
      userEmail: userEmail,
      paiment: paymentStatus,
    };
    const response = await dispatch(createBookingsAsync(finalData));
    if (createBookingsAsync.rejected.match(response)) {
      toast.error("Error creating booking !");
    } else {
      toast.success("booking created Successfully...!");
      await dispatch(getAllBookings(establishmentId));
      handleCloseModal();
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.event.title}</b>
      </>
    );
  };

  const handleEventClick = (clickInfo) => {
    const booking = bookingsData.find(
      (booking) => booking._id === clickInfo.event.id
    );
    setSelectedBookingPreview(booking);
    setPreviewModalOpen(true);
  };
  return (
    <Fragment>
      <Row>
        <Col md={12} className="box-col-70">
          <Card>
            <CardBody>
              <div className="demo-app-calendar" id="mycalendartest">
                <FullCalendar
                  initialView="dayGridMonth"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  rerenderDelay={10}
                  editable={false}
                  droppable={true}
                  selectable={true}
                  selectAllow={(selectInfo) => {
                    const today = new Date().setHours(0, 0, 0, 0);
                    const selectedStart = new Date(selectInfo.start).setHours(0, 0, 0, 0);
                    return selectedStart >= today;
                  }}
                  select={handleDateSelect}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  events={bookingsData?.map((booking) => ({
                    id: booking._id,
                    title: `${booking?.userId?.email} - Room ${booking?.roomId?.roomNumber}`,
                    start: booking?.dateArrive,
                    end: booking?.dateDepart,
                    extendedProps: {
                      status: booking.status, // Pass status as extended property
                    },
                  }))}
                  eventClassNames={(arg) => {
                    const { status } = arg.event.extendedProps;
                    if (status === "accepted") return ["booking-accepted"];
                    if (status === "rejected") return ["booking-rejected"];
                    if (status === "cancelled") return ["booking-cancelled"];
                    if (status === "pending") return ["booking-pending"];
                    return [];
                  }}
                  dayMaxEvents={2}
                  eventContent={renderEventContent}
                  eventClick={handleEventClick}
                  moreLinkClick="popover"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <PreviewBookingModal
        isOpen={previewModalOpen}
        toggle={() => setPreviewModalOpen(!previewModalOpen)}
        booking={selectedBookingPreview}
        establishmentId={establishmentId}
      />
      <CreateBookingModal
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(false);
          dispatch(cleanRoomsForBooking());
        }}
        formData={formData}
        setFormData={setFormData}
        modalSize={modalSize}
        step={step}
        handleAddBooking={handleAddBooking}
        roomAvailableForBooking={roomAvailableForBooking}
      />
    </Fragment>
  );
};

export default DragCalendar;
