import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  Row,
} from "reactstrap";

const CreateBookingModal = ({
  isOpen,
  toggle,
  onSubmit,
  formData,
  setFormData,
  modalSize,
  step,
  handleAddBooking,
  roomAvailableForBooking
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      // fullscreen
      //   onClosed={!modalOpen}
      size={modalSize}
    >
      <ModalHeader toggle={toggle}>
        {step === 0 ? "Create Booking" : "Review Booking"}
      </ModalHeader>
      <ModalBody>
        {step === 0 ? (
          <Form onSubmit={handleSubmit}>
            <Row className="g-2">
              <Col md="6">
                <FormGroup>
                  <Label for="dateArrive">Arrival Date</Label>
                  <Input
                    type="date"
                    name="dateArrive"
                    id="dateArrive"
                    value={formData.dateArrive}
                    onChange={(e) =>
                      setFormData({ ...formData, dateArrive: e.target.value })
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="dateDepart">Departure Date</Label>
                  <Input
                    type="date"
                    name="dateDepart"
                    id="dateDepart"
                    value={formData.dateDepart}
                    onChange={(e) =>
                      setFormData({ ...formData, dateDepart: e.target.value })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            {formData.bookings.map((booking, index) => (
              <Row key={index} className="g-2" style={{ marginBottom: "10px" }}>
                <Col md="2">
                  <FormGroup>
                    <Label>Room</Label>
                    <Input
                      type="text"
                      value={booking.roomId}
                      disabled // Room input is disabled, e.g. "Room 1", "Room 2", etc.
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label for={`roomType-${index}`}>Room Type</Label>
                    <Input
                      type="select"
                      name="roomType"
                      id={`roomType-${index}`}
                      value={booking.roomType}
                      onChange={(e) => handleFormChange(e, index)}
                    >
                      <option value="standard">Standard</option>
                      <option value="double">Double</option>
                      <option value="triple">Triple</option>
                      <option value="quad">Quad</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for={`nbAdulte-${index}`}>Adults</Label>
                    <Input
                      type="number"
                      name="nbAdulte"
                      id={`nbAdulte-${index}`}
                      value={booking.nbAdulte}
                      onChange={(e) => handleFormChange(e, index)}
                      min="1"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label for={`nbEnfant-${index}`}>Children</Label>
                    <Input
                      type="number"
                      name="nbEnfant"
                      id={`nbEnfant-${index}`}
                      value={booking.nbEnfant}
                      onChange={(e) => handleFormChange(e, index)}
                      min="0"
                      required
                    />
                  </FormGroup>
                </Col>
                {!index == 0 && (
                  <Col
                    md="3"
                    className="d-flex align-items-center"
                    style={{ marginBottom: "2%" }}
                  >
                    <Button
                      color="danger"
                      onClick={() => handleDeleteBooking(index)}
                      style={{ marginTop: "30px" }}
                    >
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                color="secondary"
                onClick={handleAddBooking}
                style={{ marginTop: "10px" }}
              >
                + Add Another Room
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                type="submit"
                color="success"
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                Check availability
              </Button>
            </div>

            {roomAvailableForBooking.length > 0 && (
              <AvailabileRoom
                data={roomAvailableForBooking}
                formData={formData}
                onReview={handleReviewBook}
                setReviewDataToSubmit={setReviewDataToSubmit}
              />
            )}
          </Form>
        ) : (
          <div>
            {/* Display selectedBooking data */}
            <Row className="g-2">
              <Col md="6">
                <FormGroup>
                  <Label for="userEmail">User Email</Label>
                  <Input
                    type="email"
                    name="userEmail"
                    id="userEmail"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="paymentStatus">Payment Status</Label>
                  <Input
                    type="select"
                    name="paymentStatus"
                    id="paymentStatus"
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                    required
                  >
                    {paymentOptions.map((option) => (
                      <option key={option} value={option.toLowerCase()}>
                        {option}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            {selectedBooking && (
              <BookingSummary bookingData={selectedBooking} />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button onClick={handleBack}>Previous</Button>
              <Button color="success" onClick={SubmitBooking}>
                Confirm
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CreateBookingModal;
