import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCategory,
  gelAllCategoryTree,
  getCategoriesWithoutParent,
  updateCategory,
  deleteCategory,
  getSubCategory,
  getServicesByCategoryId,
  
} from "../services/categoryService";

const initialStateCategory = {
  categoriesData: {},
  loading: false,
  error: null,
};

export const createCategoryAsync = createAsyncThunk(
  "category/create",
  async (categoryData, { rejectWithValue }) => {
    try {
      const response = await createCategory(categoryData);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateCategoryAsync = createAsyncThunk(
  "category/update",
  async ({ idCategory, categoryData }, { rejectWithValue }) => {
    try {
      const response = await updateCategory(idCategory, categoryData);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAllCategoryTreeAsync = createAsyncThunk(
  "category/fetchData",
  async ({establishment, page, perPage, tree}, { rejectWithValue }) => {
    try {
      const response = await gelAllCategoryTree(establishment, page, perPage, tree);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCategoriesWithoutParentAsync = createAsyncThunk(
  "parents/fetchData",
  async ({establishmentId, page, perPage}, { rejectWithValue }) => {

    try {
      const response = await getCategoriesWithoutParent(establishmentId, page, perPage);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);export const getSubCategoryAsync = createAsyncThunk(
  "subCategory/fetchSubCategpryData",
  async ( {categoryId, establishmentId, page, perPage }, { rejectWithValue }) => {
    try {
      const response = await getSubCategory(categoryId, establishmentId, page, perPage);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "category/deleteData",
  async (idCategory, { rejectWithValue }) => {
    
    try {
      const response = await deleteCategory(idCategory);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServicesByCategoryIdAsync = createAsyncThunk(
  "category/servicesOfCategory",
  async ({categoryId}, { rejectWithValue }) => {
    
    try {
      const response = await getServicesByCategoryId(categoryId);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: initialStateCategory,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCategoryAsync.fulfilled, (state, action) => {
      state.categoriesData.data = [...state.categoriesData.data, action.payload.data];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createCategoryAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllCategoryTreeAsync.fulfilled, (state, action) => {
      state.categoriesData = action.payload
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllCategoryTreeAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getCategoriesWithoutParentAsync.fulfilled, (state, action) => {
      state.categoriesData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getCategoriesWithoutParentAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getCategoriesWithoutParentAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubCategoryAsync.fulfilled, (state, action) => {
      state.categoriesData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSubCategoryAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSubCategoryAsync.pending, (state) => {
      state.loading = true;
    });
  
    builder.addCase(updateCategoryAsync.fulfilled, (state, action) => {
      state.categoriesData.data = state.categoriesData?.data?.map(item => 
        item._id === action.payload.data._id ? action.payload.data : item
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateCategoryAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteCategoryAsync.fulfilled, (state, action) => {
      state.categoriesData.totalData--;
      state.categoriesData.data = state.categoriesData.data.filter(
        (category) => category._id !== action.payload?.data?._id
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteCategoryAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
  },
});
export const categoryReducer = categorySlice.reducer;
const { actions } = categorySlice;
